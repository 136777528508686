<template>
  <div class="comment_wrapper">
    <p class="header_comment">نظرات</p>
    <div class="comment">
      <div class="comment__detail" style="background: #542D5B">
        <textarea rows="4" placeholder="انتقاد یا پیشنهاد خودتو بنویس" class="comment__send" v-model="comment"></textarea>
      </div>
      <button class="comment__button" @click="handleComment">ارسال نظر</button>
    </div>
    <div class="comment" v-for="comment in list" :key="comment.id">
      <img :src="baseURl + comment.user.profile" alt="">
      <div class="comment__detail">
        <h6>{{comment.user.first_name +' '+comment.user.last_name}}</h6>
        <p>{{comment.text}}</p>
        <span>{{comment.updated_at}}</span>
      </div>
    </div>
    <infinite @infinite="infiniteHandler">
      <div class="text-white text-center mt-16" slot="no-results">نظری وجود ندارد</div>
      <div slot="no-more"></div>
    </infinite>


  </div>
</template>

<script>
import axios from "../../plugins/axios";
import infinite from "vue-infinite-loading";
export default {
  name: "RealEstatePageComment",
  data(){
    return{
      baseURl : this.$store.state.general.baseURL,
      comment : '',
      page : 1,
      list : [],
    }
  },
  components :{
    infinite
  },
  methods:{
    infiniteHandler($state){
      axios.get(`/real-estates/${this.$route.params.id}/comments`,{
        params :{
          page : this.page
        }
      }).then(({data})=>{
        if (data.data.data.length) {
          this.page += 1;
          this.list.push(...data.data.data);
          $state.loaded();
        } else {
          $state.complete();
        }
      })
    },
    handleComment(){
      if (this.comment.length >= 8){
        axios.post(`/real-estates/${this.$route.params.id}/comments`,{
          text : this.comment
        }).then(()=>{
          this.comment = ''
          this.$toast.success('نظر شما با موفقیت ثبت شد پس از تایید توسط مدیر در سایت درج می شود')
        })
      }else {
        this.$toast.error('طول متن نظر باید بیشتر از 8 حروف باشد')
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>