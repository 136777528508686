<template>
  <div>
    <div class="box-profile">
      <div>
        <h4 class="text-white">حوزه فعالیت</h4>
        <div class="flex gap-y-2 flex-wrap mt-2">
          <span class="adver-type ml-2" v-for="item in activity">{{item}}</span>
        </div>
      </div>
      <div class="mt-5">
        <h4 class="text-white">راه های تماس بنگاه</h4>
        <div class="flex items-center justify-center gap-2 mt-4">
          <a v-if="data.data.telephone" :href="`tel:${data.data.telephone}`" target="_blank" class="bongah-filter"><svg xmlns="http://www.w3.org/2000/svg" width="20.879" height="20.877" viewBox="0 0 20.879 20.877">
            <path id="Icon_ionic-ios-call" data-name="Icon ionic-ios-call" d="M24.766,20.85A17.537,17.537,0,0,0,21.1,18.4c-1.1-.527-1.5-.516-2.277.043-.647.467-1.065.9-1.81.739a10.8,10.8,0,0,1-3.636-2.69,10.72,10.72,0,0,1-2.69-3.636c-.158-.75.277-1.163.739-1.81.56-.777.576-1.179.043-2.277A17.19,17.19,0,0,0,9.02,5.1c-.8-.8-.978-.625-1.419-.467a8.073,8.073,0,0,0-1.3.69,3.919,3.919,0,0,0-1.56,1.647c-.31.669-.669,1.913,1.158,5.164a28.812,28.812,0,0,0,5.066,6.756h0l.005.005.005.005h0a28.925,28.925,0,0,0,6.756,5.066c3.25,1.826,4.495,1.468,5.164,1.158a3.852,3.852,0,0,0,1.647-1.56,8.073,8.073,0,0,0,.69-1.3C25.391,21.828,25.57,21.649,24.766,20.85Z" transform="translate(-4.49 -4.503)" fill="#fff"/>
          </svg>
          </a>
          <a :href="data.data.socials[0].string === 'instagram' ? data.data.socials[0].link : data.data.socials[1].link " v-if="isShowInstagram" target="_blank" class="bongah-filter"><svg xmlns="http://www.w3.org/2000/svg" width="53.883" height="53.878" viewBox="0 0 53.883 53.878">
            <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M11.939,8.055a6.122,6.122,0,1,0,6.122,6.122A6.112,6.112,0,0,0,11.939,8.055Zm0,10.1a3.98,3.98,0,1,1,3.98-3.98,3.987,3.987,0,0,1-3.98,3.98ZM19.739,7.8a1.428,1.428,0,1,1-1.428-1.428A1.425,1.425,0,0,1,19.739,7.8Zm4.055,1.449a7.066,7.066,0,0,0-1.929-5,7.113,7.113,0,0,0-5-1.929c-1.971-.112-7.88-.112-9.852,0a7.1,7.1,0,0,0-5,1.923,7.09,7.09,0,0,0-1.929,5c-.112,1.971-.112,7.88,0,9.852a7.066,7.066,0,0,0,1.929,5,7.122,7.122,0,0,0,5,1.929c1.971.112,7.88.112,9.852,0a7.066,7.066,0,0,0,5-1.929,7.113,7.113,0,0,0,1.929-5c.112-1.971.112-7.875,0-9.846ZM21.247,21.215a4.029,4.029,0,0,1-2.27,2.27c-1.572.623-5.3.48-7.038.48s-5.472.139-7.038-.48a4.029,4.029,0,0,1-2.27-2.27c-.623-1.572-.48-5.3-.48-7.038s-.139-5.472.48-7.038A4.029,4.029,0,0,1,4.9,4.868c1.572-.623,5.3-.48,7.038-.48s5.472-.139,7.038.48a4.029,4.029,0,0,1,2.27,2.27c.623,1.572.48,5.3.48,7.038S21.87,19.648,21.247,21.215Z" transform="translate(15.005 12.762)" fill="#fff"/>
          </svg>
          </a>
          <a v-if="isShowWhatsApp" :href="`https://wa.me/${data.data.socials[0].string === 'whatsapp' ? data.data.socials[0].link : data.data.socials[1].link }`" target="_blank" class="bongah-filter">
            <svg xmlns="http://www.w3.org/2000/svg" width="54.446" height="54.446" viewBox="0 0 54.446 54.446">
              <path id="Icon_simple-whatsapp" data-name="Icon simple-whatsapp" d="M17.823,14.649c-.307-.153-1.8-.883-2.078-.984s-.482-.153-.685.153S14.274,14.8,14.1,15s-.355.214-.658.076a8.267,8.267,0,0,1-2.448-1.513A9.241,9.241,0,0,1,9.3,11.457c-.177-.306-.019-.474.132-.626.139-.138.307-.351.459-.533a2.582,2.582,0,0,0,.3-.505.56.56,0,0,0-.025-.534c-.076-.153-.684-1.65-.939-2.247s-.5-.519-.684-.519c-.175-.015-.378-.015-.582-.015a1.148,1.148,0,0,0-.812.366A3.4,3.4,0,0,0,6.089,9.364,5.96,5.96,0,0,0,7.331,12.5a13.582,13.582,0,0,0,5.195,4.568c.727.306,1.294.489,1.736.641a4.214,4.214,0,0,0,1.915.123,3.135,3.135,0,0,0,2.053-1.452,2.521,2.521,0,0,0,.183-1.451c-.075-.138-.275-.214-.581-.351m-5.547,7.581h-.016A10.189,10.189,0,0,1,7.12,20.748l-.367-.218-3.82.993,1.024-3.713-.243-.382A10.1,10.1,0,0,1,19.447,4.967a9.97,9.97,0,0,1,2.963,7.12A10.115,10.115,0,0,1,12.291,22.155M20.9,3.513A12.367,12.367,0,0,0,12.269,0,12.11,12.11,0,0,0,1.727,18.169L0,24.446l6.453-1.683a12.286,12.286,0,0,0,5.816,1.474h.006A12.162,12.162,0,0,0,24.446,12.12a12.013,12.013,0,0,0-3.56-8.567" transform="translate(15 15)" fill="#fff"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="mt-4" style="width: 100%; height: 250px;">
        <h4 class="text-white mb-4">آدرس بنگاه</h4>
        <mapir class="rounded-map" :apiKey="mapToken" :mapStyle="this.$store.state.map.options.style" :zoom="10" :center="marker">
          <mapMarker
              :coordinates="marker"
              color="blue"
              :draggable="false"
          >
            <template slot="marker">
              <img class="w-10 h-10 rounded-full" src="../../assets/images/icons/location-pin.svg" alt="">
            </template>
          </mapMarker>
        </mapir>
      </div>
      <div class="mt-4">
        <h4 class="text-white">پروانه کسب</h4>
        <div class=" flex gap-2 items-center my-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.927" height="20.927" viewBox="0 0 20.927 20.927">
            <path id="fi-rr-id-badge" d="M16.567,3.488H13.079V2.616a2.616,2.616,0,1,0-5.232,0v.872H4.36A4.365,4.365,0,0,0,0,7.848v8.72a4.365,4.365,0,0,0,4.36,4.36H16.567a4.365,4.365,0,0,0,4.36-4.36V7.848a4.365,4.365,0,0,0-4.36-4.36ZM9.592,2.616a.872.872,0,0,1,1.744,0V4.36a.872.872,0,1,1-1.744,0Zm9.592,13.951a2.616,2.616,0,0,1-2.616,2.616H4.36a2.616,2.616,0,0,1-2.616-2.616V7.848A2.616,2.616,0,0,1,4.36,5.232H8.008a2.6,2.6,0,0,0,4.911,0h3.648a2.616,2.616,0,0,1,2.616,2.616ZM8.72,8.72H4.36a.872.872,0,0,0-.872.872v6.976a.872.872,0,0,0,.872.872H8.72a.872.872,0,0,0,.872-.872V9.592A.872.872,0,0,0,8.72,8.72ZM7.848,15.7H5.232V10.464H7.848Zm9.592-2.616a.872.872,0,0,1-.872.872h-4.36a.872.872,0,0,1,0-1.744h4.36A.872.872,0,0,1,17.439,13.079Zm0-3.488a.872.872,0,0,1-.872.872h-4.36a.872.872,0,0,1,0-1.744h4.36A.872.872,0,0,1,17.439,9.592ZM15.7,16.567a.872.872,0,0,1-.872.872H12.207a.872.872,0,0,1,0-1.744h2.616A.872.872,0,0,1,15.7,16.567Z" fill="#ff7400"/>
          </svg>
          <p class="text-white font-medium">جواز کسب این بنگاه</p>
</div>
        <div class="flex justify-between mt-4">
          <a :href="baseURL + licensePicture"><img :src="baseURL + licensePicture" class="javaz-img" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapir , mapMarker} from 'mapir-vue'
import axios from "../../plugins/axios";
export default {
name: "Details",
  components :{
    mapir ,
    mapMarker
  },
  props :{
    activity : {
      type : Array,
      default : []
    },
    phone :{
      type : [String,Number]
    },
    licensePicture :{
      type : String
    }
  },
  data(){
    return{
      baseURL : this.$store.state.general.baseURL,
      mapToken : this.$store.state.map.token,
      data : null,
      marker : [50.83590215589311 ,28.963614167703895],
      coordinates : [50.83590215589311 ,28.963614167703895],
      isShowInstagram : false,
      isShowWhatsApp : false
    }
  },
  methods :{
    checkSocials(data){
      if (data.socials && data.socials !== undefined && data.socials !== null) {
        data.socials.forEach(item => {
          item.string === 'instagram' ? this.isShowInstagram = true : this.isShowInstagram = false ;
          item.string === 'whatsapp' ? this.isShowWhatsApp = true : this.isShowWhatsApp = false
        })
      }
    }
  },
  created() {

     let id = this.$route.params.id
      axios.get('real-estates/' + id).then(({data}) => {
        this.data = data
        this.checkSocials(data.data)
       this.marker =  [data.data.geoposition.longitude , data.data.geoposition.latitude]
       this.coordinates = [data.data.geoposition.longitude , data.data.geoposition.latitude]
     })


  }
}
</script>

<style scoped>
.upload-component{
  padding: unset ;
}
.upload-component >>> .vux-uploader_hd{
  display: none !important;
}
.upload-component >>> .vux-uploader_input-box{
  float: right !important;
  background: rgba(255,255,255,0.2);
  border-radius: 1.5rem;
  border: none;
}
.upload-component >>> .vux-uploader_input-box::after{
  content: '';
  background: url("../../assets/images/icons/shot_image.svg") center center no-repeat;
  width: 100%;
  height: 100%;
}
.upload-component >>> .vux-uploader_input-box::before{
  all: revert;
}
.upload-component >>> .vux-uploader_file{
  float: right !important;
  border-radius: 1.5rem;
}
</style>